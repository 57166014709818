import { useEffect, useState } from 'react';
import { Container, Row, Col, Modal, Image, ModalHeader, ModalBody, ModalFooter, Button } from 'react-bootstrap';
import voucher50 from './assets/img/voucher-50.png'
import voucher60 from './assets/img/voucher-60.png'
import voucher70 from './assets/img/voucher-70.png'
import voucher80 from './assets/img/voucher-80.png'
import voucher90 from './assets/img/voucher-90.png'
import voucher100 from './assets/img/voucher-100.png'
import PropTypes from 'prop-types';

import $ from 'jquery';
import { propTypes } from 'react-bootstrap/esm/Image';
import axios from 'axios';
import { PatternFormat } from 'react-number-format';
import { HashLoader } from 'react-spinners';
import swal from '@sweetalert/with-react';
import { auth, db, firestore } from "./Firebase";
import { signInWithPhoneNumber } from "firebase/auth"
import { collection, getDocs, query, where } from 'firebase/firestore';

function getElementIndex(element) {
    return element instanceof HTMLElement ? [...element.parentNode.children].indexOf(element) : -1
}

function getRandomInteger(min, max) {
    return min + Math.random() * (max - min)
}

function animate(render, end, from, to, duration, timeFx) {
    let startTime = performance.now()
    requestAnimationFrame(function step(time) {
        let pTime = (time - startTime) / duration
        if (pTime > 1) pTime = 1

        render(from + (to - from) * timeFx(pTime))

        if (pTime < 1) requestAnimationFrame(step)
        else end()
    })
}

function selectEasing(name) {
    const list = listEasing()
    return list[name] || list['linear']
}

function listEasing() {
    return {
        linear: (t) => t,
        easeInQuad: (t) => t * t,
        easeOutQuad: (t) => t * (2 - t),
        easeInOutQuad: (t) => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t,

        easeInCubic: (t) => t * t * t,
        easeOutCubic: (t) => (--t) * t * t + 1,
        easeInOutCubic: (t) => t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1,

        easeInQuart: (t) => t * t * t * t,
        easeOutQuart: (t) => 1 - (--t) * t * t * t,
        easeInOutQuart: (t) => t < .5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t,

        easeInQuint: (t) => t * t * t * t * t,
        easeOutQuint: (t) => 1 + (--t) * t * t * t * t,
        easeInOutQuint: (t) => t < .5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t,

        easeInSine: (t) => 1 + Math.sin(Math.PI / 2 * t - Math.PI / 2),
        easeOutSine: (t) => Math.sin(Math.PI / 2 * t),
        easeInOutSine: (t) => (1 + Math.sin(Math.PI * t - Math.PI / 2)) / 2,

        easeInElastic: (t) => (.04 - .04 / t) * Math.sin(25 * t) + 1,
        easeOutElastic: (t) => .04 * t / (--t) * Math.sin(25 * t),
        easeInOutElastic: (t) => (t -= .5) < 0 ? (.02 + .01 / t) * Math.sin(50 * t) : (.02 - .01 / t) * Math.sin(50 * t) + 1,
    }
}

/**
 * https://stackoverflow.com/questions/40710628/how-to-convert-snake-case-to-camelcase-in-my-app#answer-61375162
 * @param str
 * @returns {string}
 */
function snakeToCamel(str) {
    return str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group.toUpperCase().replace('-', '').replace('_', ''))
}

/**
 * https://zellwk.com/blog/css-translate-values-in-javascript/
 * Gets computed translate values
 * @param {HTMLElement} element
 * @returns {Object}
 */
function getTranslateValues(element) {
    const style = window.getComputedStyle(element)
    const matrix = style['transform'] || style.webkitTransform || style.mozTransform

    // No transform property. Simply return 0 values.
    if (matrix === 'none' || typeof matrix === 'undefined') {
        return {
            x: 0,
            y: 0,
            z: 0
        }
    }

    // Can either be 2d or 3d transform
    const matrixType = matrix.includes('3d') ? '3d' : '2d'
    const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(', ')

    // 2d matrices have 6 values
    // Last 2 values are X and Y.
    // 2d matrices does not have Z value.
    if (matrixType === '2d') {
        return {
            x: matrixValues[4],
            y: matrixValues[5],
            z: 0
        }
    }

    // 3d matrices have 16 values
    // The 13th, 14th, and 15th values are X, Y, and Z
    if (matrixType === '3d') {
        return {
            x: matrixValues[12],
            y: matrixValues[13],
            z: matrixValues[14]
        }
    }
}

const Spin = (props) => {
    // voucher50 = 40%, voucher60 = 20%, voucher70 = 15%, voucher80= 10%, voucher90 = 10%, voucher 100% = 5%
    const [prize, setPrize] = useState([
        { label: "Voucher Diskon 50%", key: "voucher50", img: voucher50 },
        { label: "Voucher Diskon 50%", key: "voucher50", img: voucher50 },
        { label: "Voucher Diskon 50%", key: "voucher50", img: voucher50 },
        { label: "Voucher Diskon 50%", key: "voucher50", img: voucher50 },
        { label: "Voucher Diskon 50%", key: "voucher50", img: voucher50 },
        { label: "Voucher Diskon 50%", key: "voucher50", img: voucher50 },
        { label: "Voucher Diskon 50%", key: "voucher50", img: voucher50 },
        { label: "Voucher Diskon 50%", key: "voucher50", img: voucher50 },
        { label: "Voucher Diskon 60%", key: "voucher60", img: voucher60 },
        { label: "Voucher Diskon 60%", key: "voucher60", img: voucher60 },
        { label: "Voucher Diskon 60%", key: "voucher60", img: voucher60 },
        { label: "Voucher Diskon 60%", key: "voucher60", img: voucher60 },
        { label: "Voucher Diskon 70%", key: "voucher70", img: voucher70 },
        { label: "Voucher Diskon 70%", key: "voucher70", img: voucher70 },
        { label: "Voucher Diskon 70%", key: "voucher70", img: voucher70 },
        { label: "Voucher Diskon 80%", key: "voucher80", img: voucher80 },
        { label: "Voucher Diskon 80%", key: "voucher80", img: voucher80 },
        { label: "Voucher Diskon 90%", key: "voucher90", img: voucher90 },
        { label: "Voucher Diskon 90%", key: "voucher90", img: voucher90 },
        { label: "Voucher Diskon 100%", key: "voucher100", img: voucher100 }]
    )

    const options = {
        "itemsTotal": prize.length,
        "itemsVisible": window.innerWidth > 550 ? 3 : 2,
        "totalIterations": 3,
        "duration": 1000,
        "easing": "linear",
        "easingStart": "easeInSine",
        "easingEnd": "easeOutSine"
    }

    const [element, setElement] = useState({})
    const [name, setName] = useState("")
    const [errName, setErrName] = useState(null)
    const [phone, setPhone] = useState("")
    const [errPhone, setErrPhone] = useState(null)
    const [email, setEmail] = useState("")
    const [errEmail, setErrEmail] = useState(null)
    const [loading, setLoading] = useState(false)
    const [uid, setUID] = useState("")
    const [isVerified, setIsVerified] = useState(false)
    const [history, setHistory] = useState(null)
    var hashData = ""

    const [data, setData] = useState({
        itemsVisible: window.innerWidth > 550 ? 3 : 2,
        totalIterations: 3,
        duration: 1000,
        easing: 'linear',
        easingStart: 'easeInSine',
        easingEnd: 'easeOutSine',
        started: true,
        error: null,
        currentIteration: 0,
        totalSkipped: 0,
        numberWinner: null,
        itemsTotal: prize.length,
        rouletteWidth: 0,
        wrapperWidth: 0,
        itemWidth: 0,
        maximumLeftOffset: 0,
        lastAllowedIndex: 0,
        offsetRatio: 0,
    })

    const clearError = () => {
        const element = {}
        element.roulette = document?.querySelector('.roulette')
        element.wrapper = element.roulette?.querySelector('.roulette-wrapper')
        element.items = element.wrapper?.children // life collection
        if (element.error) {
            element.error.parentNode.removeChild(element.error)
        }
    }

    const update = () => {
        const element = {}
        element.roulette = document?.querySelector('.roulette')
        element.wrapper = element.roulette?.querySelector('.roulette-wrapper')
        element.items = element.wrapper?.children // life collection
        const tempData = { ...data }

        clearError()

        // Remove all clone
        element.wrapper.querySelectorAll('.roulette-item-clone').forEach((itemClone) => element.wrapper.removeChild(itemClone))

        tempData.itemsTotal = element.items.length

        if (!tempData.itemsTotal) {
            alert('No Items Found')
            tempData.error = 'No Items Found'
            console.log('tempData.itemsTotal: ' + tempData.itemsTotal, { data, items: element.items })

            showError()
            return
        }

        if (tempData.itemsVisible > tempData.itemsTotal * 0.5) {
            tempData.error = 'The ratio of visible elements to all should be no more than 1:2. Some data is displayed in the console'
            console.log('tempData.itemsVisible: ' + tempData.itemsVisible, 'tempData.itemsTotal: ' + tempData.itemsTotal, { data, items: element.items })

            showError()
            return
        }

        tempData.rouletteWidth = element.roulette.getBoundingClientRect().width
        tempData.wrapperWidth = element.wrapper.getBoundingClientRect().width

        tempData.itemWidth = tempData.rouletteWidth / tempData.itemsVisible

        if (tempData.itemWidth < 30) {
            tempData.error = 'The width of the elements is less than 30px, the elements are almost invisible. Some data is displayed in the console'
            console.log('tempData.itemWidth: ' + tempData.itemWidth, 'tempData.rouletteWidth: ' + tempData.rouletteWidth, 'tempData.itemsVisible: ' + tempData.itemsVisible, { data })

            showError()
            return
        }

        tempData.maximumLeftOffset = tempData.itemWidth * (tempData.itemsTotal * 2) - tempData.rouletteWidth
        tempData.lastAllowedIndex = Math.floor(tempData.maximumLeftOffset / tempData.itemWidth)

        const firstOriginalItem = $('[data-position="1"]')[0]

        // Add clone
        Array.from(element.items).forEach(function (item, index) {
            item.style.flex = `0 0 ${tempData.itemWidth}px`
            item.style.minWidth = `${tempData.itemWidth}px`

            const itemClone = item.cloneNode(true)
            itemClone.classList.add('roulette-item-clone')

            if (index <= (tempData.itemsTotal / 2)) {
                element.wrapper.append(itemClone)
            } else {
                firstOriginalItem.before(itemClone)
            }
        })

        // Смещение на первый элемент

        const leftOffset = getOffsetForItem(firstOriginalItem, 0.5)
        element.wrapper.style.transform = `translate(-${leftOffset}px, 0)`

        // Снимем блокировку, можем крутить
        tempData.started = false
        setData(tempData)
    }

    const generateDemoRoulette = async () => {
        var tempPrize = shuffle()
        const roulette = document.querySelector('.roulette')
        const rouletteWrapper = roulette.children[0]
        const itemsTotal = data.itemsTotal
        const itemsVisible = data.itemsVisible

        rouletteWrapper.innerHTML = ''

        const rouletteWidth = roulette.getBoundingClientRect().width
        const itemWidth = rouletteWidth / itemsVisible

        for (let i = 1; i <= itemsTotal; i++) {
            const item = document.createElement('div')
            item.className = 'roulette-item roulette-item-demo'
            item.setAttribute('data-position', i)
            const svgIcon = `<img src="${prize[i - 1].img}" class="mb-3 item-image"></img>`
            item.innerHTML = `<div class="roulette-item-inner">
    <div class="roulette-item-icon">${svgIcon}</div>
    <div class="roulette-item-text">${prize[i - 1].label}</div>
  </div>`
            item.style.flex = `0 0 ${itemWidth}px`
            item.style.minWidth = `${itemWidth}px`

            rouletteWrapper.append(item)
        }
        console.log(rouletteWrapper, "Roulette Update")
        setPrize(tempPrize)
        update();
    }

    const shuffle = () => {
        var currentIndex = prize.length, temporaryValue, randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = prize[currentIndex];
            prize[currentIndex] = prize[randomIndex];
            prize[randomIndex] = temporaryValue;
        }
        return prize
    }

    const start = async () => {
        var removePatternPhone = phone.replaceAll("_", "").replaceAll(" ", "").replaceAll("-", "").replaceAll("(", "").replaceAll(")", "")
        try {
            const isExist = isUserExist();
            if (isExist) {
                await axios.post(`${process.env.REACT_APP_API_URL}/lucky-draw/generate`, {
                    prize: prize,
                    user: {
                        name: name,
                        phone: removePatternPhone,
                    }
                }).then((res) => {
                    hashData = res.data?.hash
                    const winner = res.data.prizeIndex
                    console.log(res.data)
                    if (data.started) {
                        if (data.error) {
                            showError()
                        }
                        return false
                    }

                    const item = getItemByPosition(winner)

                    // if (!item) {
                    //     data.error = 'Вы не указали элемент, который должен победить'
                    //     console.log({ winner, item })
                    //     showError()
                    //     return
                    // }

                    data.currentIteration = 0
                    data.totalSkipped = 0
                    data.numberWinner = winner
                    data.started = true

                    unmarkActiveItem()

                    // console.clear()
                    console.log(`Roulette starting...`)

                    roll()
                })
            }
        } catch (err) {
            swal(<div>
                <p style={{ marginBottom: '5px' }}>Terjadi kesalahan pada sistem, silahkan coba lagi.</p>
            </div>, {
                title: 'Error!',
                icon: "error"
            })
        }
    }

    const roll = () => {
        const { totalIterations, numberWinner, currentIteration, totalSkipped } = data
        const { items, wrapper } = element

        const isStart = currentIteration == 0
        const isEnd = currentIteration == totalIterations

        console.log(`---Iteration: ${currentIteration}`)

        // Этого не случится, но что б во время отладки не накосячить
        if (currentIteration > totalIterations) {

            data.started = false
            data.totalSkipped = 0
            return false
        }

        if (totalSkipped > 10) {

            data.started = false
            data.totalSkipped = 0
            return false
        }

        // Текущий элемент по центру
        let { position: currentCenterElementPosition, index: currentCenterElementIndex, element: currentCenterElement } = getItemByOffset()
        let movedToStart = false

        const currentCenterElements = getItemsByPosition(currentCenterElementPosition)

        // Если текущий центральный элемент находится во второй половине, вернёмся к началу
        if (currentCenterElementIndex > items.length / 2) {
            currentCenterElement = currentCenterElements[0]

            const leftOffset = getOffsetForItem(currentCenterElement, data.offsetRatio);
            wrapper.style.transform = `translate(-${leftOffset}px, 0)`

            movedToStart = true

            currentCenterElementIndex = getElementIndex(currentCenterElement)
        }

        // Пока что укажем, что наш ориентир - это последний оригинальный элемент
        let itemStop = getLastOriginalItem()

        // Если это последняя итерация, нам нужно крутить до правильного элемента
        if (isEnd) {
            const winnerElements = getItemsByPosition(numberWinner)
            const firsWinnerElementIndex = getElementIndex(winnerElements[0])

            if (firsWinnerElementIndex - currentCenterElementIndex > data.itemsVisible) {
                itemStop = winnerElements[0]
            } else {
                const lastWinnerElementIndex = getElementIndex(winnerElements[1])

                if (lastWinnerElementIndex - currentCenterElementIndex > data.itemsVisible) {
                    itemStop = winnerElements[1]
                } else {
                    // Ещё не придумал...
                    console.log('Something wrong, please try again later.')
                    data.totalSkipped++
                    swal(<div>
                        <p style={{ marginBottom: '5px' }}>Terjadi kesalahan pada sistem, silahkan coba lagi.</p>
                    </div>, {
                        title: 'Error!',
                        icon: "error"
                    })
                    return roll()
                }
            }
        }

        // Координаты для смещения рулетки
        const winnerItemLeftOffset = getOffsetForItem(itemStop)
        // Текущие координаты рулетки
        const wrapperPositionLeft = getWrapperLeftOffset()
        // Дистанция которую нужно преодолеть
        const distanceToWinnerItem = winnerItemLeftOffset - Math.abs(wrapperPositionLeft);

        console.log(`---Calculate distance (${distanceToWinnerItem}px)`)

        // Если дистанция отрицательная или равно 0, значит мы не всё просчитали
        if (distanceToWinnerItem <= 0) {
            // Ещё не продумал...
            console.log('Something wrong, please try again later')
            data.totalSkipped++
            swal(<div>
                <p style={{ marginBottom: '5px' }}>Terjadi kesalahan pada sistem, silahkan coba lagi.</p>
            </div>, {
                title: 'Error!',
                icon: "error"
            })
            return false
        }

        let from = wrapperPositionLeft
        let to = -winnerItemLeftOffset

        // Рулетка не должна смещаться за область видимости
        if (winnerItemLeftOffset > data.maximumLeftOffset) {
            // Ещё не продумал...
            console.log('Something wrong, please try again later')
            data.totalSkipped++
            swal(<div>
                <p style={{ marginBottom: '5px' }}>Terjadi kesalahan pada sistem, silahkan coba lagi.</p>
            </div>, {
                title: 'Error!',
                icon: "error"
            })
            return false
        }

        const { duration, easing, easingStart, easingEnd } = data
        const timeFx = isStart ? selectEasing(easingStart) : (isEnd ? selectEasing(easingEnd) : selectEasing(easing));

        const step = (now) =>
            wrapper.style.transform = `translate(${now}px, 0)`;

        const end = async () => {
            if (data.currentIteration == totalIterations) {
                console.log(`---End Animation`)
                // addHistory(itemStop)
                itemStop.classList.add('active')
                // console.log(hashData)
                // await axios.post('http://localhost:5000/lucky-draw/submit', {
                await axios.post(`${process.env.REACT_APP_API_URL}/lucky-draw/submit`, {
                    hash: hashData
                }).then((res) => {
                    setLoading(false)
                    console.log(res)
                    if (res.data?.message === "OK") {
                        res.data.prize.code = res.data.voucher
                        localStorage.setItem("prize", JSON.stringify(res.data.prize))
                        swal(<div>
                            <p style={{ marginBottom: '5px' }}><b>KODE VOUCHER : {res.data.voucher}</b></p>
                            <p style={{ marginTop: '0px' }}>Anda mendapatkan <b>{res.data.prize.label}</b>!</p>
                            <p style={{ marginTop: '0px' }}>Berlaku sampai tanggal <b>23 Maret 2024</b>.</p>
                        </div>, {
                            title: 'Selamat!',
                            icon: "success"
                        })
                    } else {
                        swal(<div>
                            <p style={{ marginBottom: '5px' }}>Terjadi kesalahan pada sistem, silahkan coba lagi.</p>
                        </div>, {
                            title: 'Error!',
                            icon: "error"
                        })
                    }
                })

                data.started = false
            } else {
                data.currentIteration++
                console.log(`---Starting the next circle (${data.currentIteration})`)
                roll()
            }
        }


        console.log(`---Animating`)
        animate(step, end, from, to, duration, timeFx)
    }

    const showError = () => {
        data.error = data.error || false

        if (data.error) {
            const error = document.createElement('div')
            error.className = 'alert alert-danger'
            error.textContent = data.error

            element.roulette.after(error)

            element.error = error
        }
    }

    const addHistory = (item) => {
        setHistory(item)
        element.history = element.history || document.querySelector('.roulette-history')

        console.log(item)
        if ($('.roulette-history').children().length === 0) {
            element.history.prepend()
            element.history.closest('.section-roulette-history').classList.add('in')
            $('.roulette-history').prepend(`<img style="width: 150px;" src=${(process.env.NODE_ENV === "production" ? process.env.REACT_APP_URL : "") + item.img}>`)
            $('.roulette-history').append(`<label>${item.label} - <b>${item.code}<b/><label/>`)

        }
    }

    const unmarkActiveItem = () => {
        const activeItem = element.wrapper.querySelector('.active')

        if (activeItem) {
            activeItem.classList.remove('active')
        }
    }

    const getItemByOffset = (offset = false) => {
        const tempElement = {}
        tempElement.roulette = document?.querySelector('.roulette')
        tempElement.wrapper = tempElement.roulette?.querySelector('.roulette-wrapper')
        tempElement.items = tempElement.wrapper?.children // life collection
        if (offset === false) {
            offset = getWrapperLeftOffset()
        }

        const { rouletteWidth, itemWidth } = data
        const { items } = tempElement

        // Дополнительно половина видимой части рулетки
        offset -= rouletteWidth / 2;

        // Что бы индекс не был отрицательным числом
        offset = Math.abs(offset)

        // Делим отступ на ширину элемента (получим индекс)
        const index = Math.floor(offset / itemWidth);
        const element = items[index]
        const position = element.dataset.position

        return { element, index, position }
    }

    const getWrapperLeftOffset = () => {
        const { wrapper } = element
        const { x } = getTranslateValues(wrapper)

        return x * 1
    }
    const getOriginalItems = (items) => {
        console.log(items)
        items = items || element.items
        return Array.from(items).filter(item => !item.classList.contains('roulette-item-clone'))
    }
    const getLastOriginalItem = () => {
        const items = getOriginalItems()
        return items[items.length - 1]
    }
    const getFirstOriginalItem = () => {
        const items = getOriginalItems()
        return items[0]
    }
    const getItemsByPosition = (position) => {
        const { items } = element
        const filtered = Array.from(items).filter(item => item.dataset.position == position)
        return filtered
    }
    const getItemByPosition = (position, original = true) => {
        const items = getItemsByPosition(position)
        const filtered = original ? getOriginalItems(items) : items
        return filtered[0]
    }
    const getOffsetForItem = (item, ratio = false) => {
        const index = getElementIndex(item)
        const { itemWidth, rouletteWidth } = data

        let offset = index * itemWidth - rouletteWidth / 2;

        const ratioMin = 0.1
        const ratioMax = 0.9

        if (ratio === false || ratio <= ratioMin || ratio >= ratioMax) {
            // Генерируем случайное число от 0.1 до 0.9 (от 10 до 90 и делим на 100, что бы получить 2 числа после запятой)
            ratio = getRandomInteger(ratioMin * 100, ratioMax * 100) / 100
        }

        data.offsetRatio = ratio

        offset += itemWidth * ratio

        return offset
    }

    const isUserExist = async () => {
        var prize = localStorage.getItem("prize")
        var tmpPhone = localStorage.getItem("phone")
        var removePatternPhone = phone.replaceAll("_", "").replaceAll(" ", "").replaceAll("-", "").replaceAll("(", "").replaceAll(")", "")
        localStorage.setItem("phone", removePatternPhone)
        if (prize && tmpPhone) {
            addHistory(prize)
            setLoading(false)
            return true
        } else {
            const q = query(collection(firestore, "vouchers"), where("user.phone", "==", removePatternPhone));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                console.log(doc.data())
                if (doc.data()) {
                    var tmpPrize = doc.data().prize
                    tmpPrize.code = doc.data().code
                    localStorage.setItem("prize", JSON.stringify(tmpPrize))
                    addHistory(tmpPrize)
                    setLoading(false)
                    return true
                } else {
                    return false
                }
            });
        }

    }

    const OTPSubmit = (phone) => {
        let opt_number = $("#otp").val();
        console.log(opt_number)

        try {
            window.confirmationResult
                .confirm(opt_number)
                .then(async (credential) => {
                    console.log(credential);
                    console.log("success");
                    localStorage.setItem("phone", phone)
                    var prize = localStorage.getItem("prize")
                    if (prize?.length > 0) {
                        addHistory(JSON.parse(prize))
                    } else {
                        const q = query(collection(firestore, "vouchers"), where("user.uid", "==", credential.user.uid));
                        const querySnapshot = await getDocs(q);
                        querySnapshot.forEach((doc) => {
                            var tmpPrize = doc.data().prize
                            tmpPrize.code = doc.data().code
                            localStorage.setItem("prize", JSON.stringify(tmpPrize))
                            addHistory(tmpPrize)
                        });
                    }
                    setUID(credential.user.uid)
                    setIsVerified(true)
                    swal.close()
                    swal(<div>
                        <p style={{ marginBottom: '5px' }}>Verifikasi Nomor Berhasil!</p>
                    </div>, {
                        title: 'Success!',
                        icon: "success"
                    })
                })
        } catch (err) {
            swal(<div>
                <p style={{ marginBottom: '5px' }}>Verifikasi gagal, silahkan coba lagi.</p>
            </div>, {
                title: 'Error!',
                icon: "error"
            })
        }
        setLoading(false)
    };

    const sendOTP = (e) => {
        e.preventDefault()
        setLoading(true)
        const appVerifier = window.recaptchaVerifier;
        var removePatternPhone = phone.replaceAll("_", "").replaceAll(" ", "").replaceAll("-", "").replaceAll("(", "").replaceAll(")", "")
        console.log(removePatternPhone)
        signInWithPhoneNumber(auth, removePatternPhone, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                console.log("otp sent");
                // setViewOtpForm(true);
                window.confirmationResult = confirmationResult;
                console.log(confirmationResult)
                swal(<div className="mb-3">
                    <label htmlFor="name" className="form-label">
                        <b>Kode OTP berhasil dikirim.</b>
                        <p><b>Silahkan masukkan kode OTP disini.</b></p></label>
                    <input type="text" className="form-control" id="otp" placeholder='Input OTP' required />
                </div>, {
                    title: 'Verifikasi Nomor Handphone',
                    icon: "warning",
                    button: {
                        text: "Submit",
                        value: true,
                        visible: true,
                        closeModal: false,
                    }
                }).then((isSubmit) => {
                    if (isSubmit && $("#otp").val() !== "") OTPSubmit(removePatternPhone)
                })
                // ...
            })
            .catch((error) => {
                // Error; SMS not sent
                // ...
                setLoading(false)
                swal(<div>
                    <p style={{ marginBottom: '5px' }}>Terjadi kesalahan pada sistem, silahkan coba lagi beberapa saat kemudian.</p>
                </div>, {
                    title: 'Error!',
                    icon: "error"
                })
            });
    }
    const init = async () => {
        var phone = localStorage.getItem("phone")
        const q = query(collection(firestore, "vouchers"), where("user.phone", "==", phone));
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            var tmpPrize = doc.data().prize
            tmpPrize.code = doc.data().code
            if (doc.data()) {
                localStorage.setItem("prize", JSON.stringify(tmpPrize))
                addHistory(tmpPrize)
            }
        });
        setLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        init();
        const tempElement = {}
        tempElement.roulette = document?.querySelector('.roulette')
        tempElement.wrapper = tempElement.roulette?.querySelector('.roulette-wrapper')
        tempElement.items = tempElement.wrapper?.children // life collection
        setElement(tempElement)


        const inputsSelector = [
            'input-items-visible', 'input-total-iterations', 'input-duration', 'select-easing', 'select-easing-start', 'select-easing-end'
        ]

        for (const id of inputsSelector) {
            const element = document.querySelector(`#${id}`)

            if (element && element.value) {
                const id2arr = id.split('-')
                id2arr.shift()

                if (id2arr.length > 1) {
                    options[snakeToCamel(id2arr.join('-'))] = element.value
                } else {
                    options[id2arr[0]] = element.value
                }
            }
        }
        generateDemoRoulette()
    }, [])

    return (
        <Col>
            {/* <Roulette /> */}
            {!history && <form className='mb-5'>
                <div className="mb-3" id="form-input">
                    <label htmlFor="name" className="form-label"><b>Nama</b></label>
                    <input type="text" className="form-control" value={name} onChange={(e) => setName(e.target.value)} placeholder='Input Nama' required />
                    {!isNaN(name) && <b className='form-error'>{errName}</b>}
                </div>
                <div className="mb-3" id="form-input">
                    <label htmlFor="phone" className="form-label-phone"><b>No. Handphone</b></label>
                    <div className='input-group'>
                        <PatternFormat
                            className="form-control"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder='Input Nomor Handphone'
                            format="(+62) ###-####-####" allowEmptyFormatting mask="_"
                            minLength={9}
                            max={11}
                            required
                        // disabled={isVerified}
                        />
                    </div>
                    {errPhone && <b className='form-error'>{errPhone}</b>}
                </div>
                {/* <div className="mb-3" id="form-input">
                    <label htmlFor="email" className="form-label-email"><b>Email</b></label>
                    <div className='input-group'>
                        <input type="text" className="form-control" value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder='Input Email' required />
                        {!isVerified && <div className="input-group-append">
                            <button className="btn btn-primary" type="button" onClick={(e) => sendOTP(e)}>Verify</button>
                        </div>}
                    </div>
                    {errEmail && <b className='form-error'>{errEmail}</b>}
                </div> */}

            </form>}
            <div className="section-roulette-history mb-5">
                <h3>History</h3>
                <div className="roulette-history"></div>
            </div>
            {!history && <div className="roulette roulette-demo">
                <div className="roulette-wrapper">

                </div>
            </div>}
            <HashLoader loading={loading} className='w-100' />
            {loading && <p style={{ textAlign: "center" }}>Loading...</p>}
            {!history && <button className='btn btn-primary btn-roulette-start w-100' style={{
                fontSize: "20px",
                fontWeight: "500",
                lineHeight: "8px",
                fill: " #4F6649",
                color: "#4F6649",
                backgroundColor: "#F2CCAF",
                borderRadius: "20px 20px 20px 20px",
                boxShadow: "-1px 3px 3px -3px rgba(0,0,0,0.5)",
                border: "0px",
                height: "40px"
            }} onClick={(e) => {
                e.preventDefault();
                var removePatternPhone = phone.replaceAll("_", "").replaceAll(" ", "").replaceAll("-", "").replaceAll("(+62)", "")
                // const isEmail = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
                if (removePatternPhone.length > 9 && name.length > 0) {
                    setLoading(true)
                    setErrName(null)
                    setErrPhone(null)
                    // setErrEmail(null)
                    $(".form-control").remove()
                    $('label[for="name"]').html(`<b>Nama : </b> ${name}`)
                    $('label[for="phone"]').html(`<b>No. Handphone : </b> ${phone}`)
                    // $('label[for="eail"]').html(`<b>Email : </b> ${email}`)
                    $(e?.target).addClass('hidden');
                    // isUserExist();
                    start();
                } else {
                    if (name === "" || isNaN(name)) setErrName("Input Nama harus diisi")
                    if (removePatternPhone.length > 0 && removePatternPhone.length < 9) setErrPhone("Minimal 9 digit angka")
                    else if (removePatternPhone.length === 0) setErrPhone("Input No. Handphone harus diisi")
                    // else if (!isVerified) setErrPhone("Verifikasi Nomor terlebih dahulu")
                    // if (email.length === 0) setErrEmail("Input Email harus diisi")
                    // else if (!isEmail) setErrEmail("Format Email salah")

                }
            }}>Start</button>}
        </Col>
    );
}

Spin.propTypes = {
    setError: PropTypes.func
};

export default Spin;